import { useTheme } from "@suid/material";
import { type ParentComponent } from "solid-js";

export const TileHeader: ParentComponent<{ showDivider?: boolean }> = (props) => {
    const theme = useTheme();
    return <>
        {props.children
            ? (<div style={{
                "grid-column": "1/-1",
                "padding": theme.spacing(1, 0, 1, 2),
                "border-top": props.showDivider
                    ? `1px solid ${theme.palette.border}`
                    : undefined,
            }}>
                {props.children}
            </div>)
            : undefined
        }</>;
};
