import { alpha, useTheme } from "@suid/material";
import { type Component, For, Show, createEffect, createMemo, createSignal, splitProps } from "solid-js";
import { type GridItem, type GridProps } from ".";
import { StyledGrid } from "./Grid.styled";
import { Tile } from "../Tile/Tile";
import { TileHeader } from "../Tile/TileHeader";

export const Grid: Component<GridProps> = (props) => {
    const theme = useTheme();
    const [local, other] = splitProps(props, ["items", "multiSelect", "showLabels", "onSelection", "showLabels", "round", "error", "direction", "tileRadius", "tileSize", "selectedTileSize", "maxTileSize", "maxSelectedTileSize"]);
    const [selectedItems, setSelectedItems] =
        // eslint-disable-next-line solid/reactivity
        createSignal<Array<GridItem | undefined>>(new Array<undefined>(local.multiSelect ?? 0).fill(undefined));

    createEffect(() => {
        if (!local.error) {
            setSelectedItems(new Array<undefined>(local.multiSelect ?? 0).fill(undefined));
        }
    });

    const selectionCount = createMemo(() => Math.min(other.mobile, local.multiSelect ?? 0));

    const addPicture = (id: string): void => {
        const selectedItem = local.items.find((item) => item?.id === id);
        if (!local.multiSelect) {
            if (selectedItem) local.onSelection(selectedItem);
        }

        const emptySlot = selectedItems().findIndex((slot) => slot === undefined);
        if (emptySlot < 0) return;

        if (selectedItems().includes(selectedItem)) return;

        setSelectedItems((items) => {
            const newItems = items.slice();
            newItems[emptySlot] = selectedItem;
            return newItems;
        });

        if (!selectedItems().includes(undefined)) {
            local.onSelection(selectedItems() as GridItem[]);
        }
    };

    const deletePicture = (id: unknown): void => {
        const idIndex = selectedItems().findIndex((item) => item?.id === id);
        if (idIndex < 0) return;

        setSelectedItems((items) => {
            const newItems = items.slice();
            newItems[idIndex] = undefined;
            return newItems;
        });
    };

    // eslint-disable-next-line solid/reactivity
    const border = local.error ? theme.palette.error.main : theme.palette.border;
    const categorized = createMemo(() => {
        const categories = new Map<string | undefined, GridItem[]>();
        local.items.forEach((item) => {
            const items = categories.get(item.category) ?? [];
            items.push(item);
            categories.set(item.category, items);
        });
        return categories;
    });

    const selection = createMemo(() => <>
        {/* eslint-disable-next-line solid/prefer-for -- We need to redraw on all reactive value changes */}
        {selectedItems().map((item) => <Tile
            placeholder
            id={String(item?.id)}
            name={item?.name ?? ""}
            image={item?.image}
            nameShown={local.showLabels}
            circular={local.round}
            radius={local.tileRadius}
            onClick={deletePicture}
            error={local.error}
        />)}</>);

    return (
        <div style={{
            "display": "flex",
            "height": local.direction === "horizontal" ? "calc(100vh - 240px)" : "auto",
            "overflow": "hidden",
            "flex": "auto",
            "border": `1px solid ${border}`,
            "background-color": local.error ? alpha(theme.palette.error.light, 0.2) : "inherit",
            "flex-direction": local.direction === "horizontal" ? "row" : "column",
            "border-radius": theme.spacing(4),
            "transition": "background-color 0.3s",
        }}>
            <StyledGrid {...other} tileSize={local.tileSize} data-testid="ItemGrid" sx={{
                flex: "1",
                p: 1.5,
                pb: local.direction === "horizontal" ? 0 : 1,
            }}>
                <For each={Array.from(categorized().entries())}>
                    {([category, items], idx) => (<>
                        <TileHeader showDivider={!!idx()}>{category}</TileHeader>
                        {<For each={items}>
                            {(item) =>
                                <Tile
                                    id={String(item?.id)}
                                    name={item.name ?? ""}
                                    image={item.image}
                                    nameShown={local.showLabels}
                                    circular={local.round}
                                    radius={local.tileRadius}
                                    onClick={addPicture}
                                    disabled={selectedItems().includes(item)}
                                />
                            }
                        </For>}
                    </>)}
                </For>

            </StyledGrid>
            <Show when={local.multiSelect}>
                <div style={{ [`border-${local.direction === "horizontal" ? "left" : "top"}`]: `1px solid ${border}` }}>
                    <StyledGrid
                        // Note: tiny thumbs by default as per design
                        {...other}
                        tileSize={local.selectedTileSize}
                        maxTileSize={local.maxSelectedTileSize}
                        desktop={local.direction === "horizontal" ? 1 : selectionCount()}
                        mobile={local.direction === "horizontal" ? 1 : selectionCount()}
                        sx={{
                            p: local.direction === "horizontal" ? 0.5 : 1,
                            overflow: "hidden",
                            animation: local.error ? "wiggle 2.5s normal" : undefined,
                            animationDelay: "0.5s",
                        }}
                        data-testid="SelectionGrid"
                    >
                        {selection()}
                    </StyledGrid>
                </div>
            </Show>
        </div>
    );
};
