import { alpha } from "@suid/material";
import styled from "@suid/system/styled";
import { type AppFrameProps } from ".";

export const AppFrameStyled = styled("div")<AppFrameProps>(
    {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    (params) => {
        const props = params.props as AppFrameProps;
        const { theme } = params;
        const { reserveAppBarHeight, fullWidth, minContentWidth } = props;

        return {
            "paddingTop": reserveAppBarHeight ? theme.spacing(8) : 0,
            "display": "flex",
            "zIndex": 0,
            "minWidth": "min(420px, 100%)",

            "& > .frame": {
                "margin": "auto",
                "maxWidth": minContentWidth ? "100%" : "1200px",
                "width": fullWidth
                    ? "auto"
                    : minContentWidth
                        ? "min-content"
                        : "420px",
                "minWidth": "min(420px, 100%)",
                "> .MuiPaper-root": {
                    minHeight: "820px",
                    marginTop: theme.spacing(2),
                },
                "> footer": {
                    "textAlign": "right",
                    "paddingTop": theme.spacing(4),
                    "marginTop": theme.spacing(-3),
                    "position": "relative",
                    "whiteSpace": "nowrap",
                    "overflow": "hidden",
                    "paddingBottom": theme.spacing(1),
                    "color": "rgba(255, 255, 255, 0.5)",
                    ".MarkdownLink, .MarkdownLink a": {
                        color: theme.palette.common.white,
                    },
                    "&::before": {
                        // Separate lowered background so we keep interactivity on the links
                        zIndex: "-1",
                        backgroundColor: alpha(theme.palette.background.default as string, 0.7),
                        borderRadius: `0 0 ${theme.spacing(3)} ${theme.spacing(3)}`,
                        content: '""',
                        display: "block",
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        top: "0px",
                    },
                },
            },

            "& > .frame.minheight > .MuiPaper-root": {
                minHeight: "initial",
            },

            "& > .frame.fullscreen": {
                "width": "100%",
                "height": "100%",
                "minWidth": "auto",
                "> .MuiPaper-root": {
                    borderRadius: 0,
                    minHeight: "initial",
                    height: "100%",
                    overflow: "auto",
                    marginTop: 0,
                },
                "& footer .MarkdownLink": {
                    color: theme.palette.text.link,
                },
            },
        };
    },
);
