import { useMediaQuery } from "@suid/material";
import UAParser, { DEVICE, OS } from "ua-parser-js";
/* eslint-disable max-len */
// Central screen Presenter webview user agents:
// Mozilla/5.0 (Central; Einstein Central 4.0) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/76.0.3798.0 Safari/537.36
// Mozilla/5.0 (Central; Eisler Central 4.0) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/81.0.4044.76 Safari/537.36
// Mozilla/5.0 (Central; Kepler Central 4.0) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/119.0.6045.163 Safari/537.36
// Mozilla/5.0 (Central; Volta Central 4.0) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/126.0.6478.126 Safari/537.36
// Mozilla/5.0 (Central; Wodan Central 4.0) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/126.0.6478.126 Safari/537.36
// Mozilla/5.0 (Central; Odin Central 4.0) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/126.0.6478.126 Safari/537.36

// Legacy user agent
// Mozilla/5.0 (X11; Linux x86_64; Prowise-Central:3.1; Einstein) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/81.0.4044.76 Safari/537.36

// Current Brave browser user agent
// Mozilla/5.0 (X11; Linux x86_64; Prowise-Central:4.1.1; Einstein) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/98.0.4758.116 Safari/537.36
/* eslint-enable max-len */

const WEBVIEW = "Webview";
const PROWISE = "Prowise";
const CENTRAL = "Central";

const centralRegex = /(?:\(|;\s*)Prowise-Central:([0-9.]+);\s*([^)]+)\)/i;
const centralWebviewRegex = /(?:\(|;\s*)([a-z]+)\s+(Central)[:\s]([0-9.]+)/i;

const deviceRegexes = [
    [centralRegex], [[DEVICE.VENDOR, PROWISE], DEVICE.MODEL],
    [centralWebviewRegex], [DEVICE.MODEL, [DEVICE.VENDOR, PROWISE], [DEVICE.TYPE, WEBVIEW]],
];
const osRegexes = [
    [centralRegex], [OS.VERSION, [OS.NAME, CENTRAL]],
    [centralWebviewRegex], [[OS.NAME, CENTRAL], [null], OS.VERSION],
];

const uaParser = new UAParser(undefined, { device: deviceRegexes, os: osRegexes });
const userAgent = uaParser.getResult();

export const isPdfJsSupported = (): boolean => {
    // Assuming https://github.com/mozilla/pdf.js/releases/tag/v4.2.67 from 29-04-2024:
    // Chrome/Chromium and edge 98+
    if (userAgent.browser.name?.match(/^(?:Chrom(?:e|ium)|Edge)/) && userAgent.browser.major && parseInt(userAgent.browser.major) < 98) return false;
    // Firefox ESR 115+
    if (userAgent.browser.name?.match(/^Firefox/) && userAgent.browser.major && parseInt(userAgent.browser.major) < 115) return false;
    // Opera 84+; see https://en.wikipedia.org/wiki/History_of_the_Opera_web_browser#Opera_2022 for version comparison.
    if (userAgent.browser.name?.match(/^Opera/) && userAgent.browser.major && parseInt(userAgent.browser.major) < 84) return false;
    // Safari 16.4+
    if (userAgent.browser.name?.match(/Safari$/) && userAgent.browser.major && parseInt(userAgent.browser.major) < 16) return false;

    // Don't assume unsupported browser
    return true;
};

// List of screens we currently have with screens what are not supported or deem to work are commented out.
const deviceModelsCheckRatio = [
    // "Heisenberg", // Presumably the oldest, unsupported
    // "Maxwell", // No app store support
    "Einstein",
    // "Hermes", // No P10 support
    // "Eisler", // Usable device pixel ratio (1.5)
    "Kepler",
    "Volta",
    "Wodan",
    "Odin",
];

export const isVendorProwise = (): boolean => userAgent.device.vendor === PROWISE;
export const isOsCentral = (): boolean => userAgent.os.name === CENTRAL;
// Note: browser "type" should be "inapp" for Webview,
// but extending the parser will remove all the browser related information.
export const isTypeWebview = (): boolean => userAgent.device.type === WEBVIEW;

const isModelToCheck = (): boolean => deviceModelsCheckRatio.includes(userAgent.device.model ?? "");

// Check Prowise Central Webview selected models with device pixel ratio set to 3
if (isVendorProwise() &&
    isOsCentral()
) {
    document.body.classList.add("central");

    if (isTypeWebview() &&
        isModelToCheck() &&
        window.devicePixelRatio === 3
    ) {
        // Add classname to indicate Central presenter webview
        document.body.classList.add("zoomed");
    }
}

// Note that the unit test can't handle "(1035px <= width <= 1050px)" syntax.
export const chromeBookQuery = "(min-width: 1035px) and (max-width: 1050px) and (min-height: 480px) and (max-height: 495px)";
// TODO: after we get some better insights of the Chromebook user agent, we can upgrade this method.
export const isChromebook = useMediaQuery(`@media (${chromeBookQuery})`);
