import { alpha, createTheme, lighten, ThemeProvider as SharedUIThemeProvider, type Theme } from "@suid/material";
import { type ParentComponent } from "solid-js";
import { prowiseDefaultPalette } from "./palette";

export const ThemeProvider: ParentComponent = (props) => {
    const theme: Theme = createTheme({
        shape: {
            borderRadius: 8,
        },
        palette: prowiseDefaultPalette,
        typography: {
            fontFamily: ["Poppins", "sans-serif"].join(","),
            button: {
                textTransform: "none",
            },
        },
        components: () => ({
            MuiPaper: {
                defaultProps: {
                    elevation: 3,
                },
            },
        }),
        // We need mixins because components.styleOverrides do not work in SolidJS/SUID
        mixins: () =>
            ({
                toolbar: {},
                paper: {
                    borderRadius: 4,
                    padding: 6,
                    boxShadow: "none",
                },
                thirdPartyButton: {
                    // Styled as per https://developer.apple.com/design/human-interface-guidelines/sign-in-with-apple#Creating-a-custom-Sign-in-with-Apple-button
                    // TODO: use logo with included margins
                    apple: {
                        color: "#000000",
                        background: "#FFFFFF",
                        border: "1px solid #000000",
                        minWidth: "140pt",
                        maxWidth: "375pt",
                        minHeight: "30pt",
                        maxHeight: "60pt",
                    },
                    // Styled as per https://developers.google.com/identity/branding-guidelines#custom-button
                    google: {
                        color: "#1F1F1F",
                        background: "#FFFFFF",
                        fontFamily: "Roboto Medium, Poppins",
                        fontSize: "14px",
                        lineHeight: "20px",
                        border: "1px solid #747775",
                        height: "40px",
                    },
                    // Styled as per https://learn.microsoft.com/en-us/entra/identity-platform/howto-add-branding-in-apps#pictogram-and-sign-in-with-microsoft
                    microsoft: {
                        color: "#5E5E5E",
                        background: "#FFFFFF",
                        fontFamily: "Segoe UI Regular, Segoe Failover", // Note: Segoe is a licensed font.
                        fontWeight: 600,
                        fontSize: "15px",
                        border: "1px solid #8C8C8C",
                        height: "41px",
                    },
                },
                button: {
                    "borderRadius": 2.5,
                    "height": "36px",
                    "boxShadow": "none",
                    "px": 3,
                    ".MuiButton-startIcon": { ml: 0, mr: 2 },
                    "color": theme.palette.common.white,
                    "&:hover": {
                        boxShadow: "none",
                        backgroundColor: lighten(theme.palette.secondary.main, 0.3),
                    },
                },
                buttonOutlined: {
                    ".MuiButton-outlinedPrimary": {
                        color: theme.palette.secondary.main,
                    },
                    ".MuiButton-outlinedSecondary": {
                        color: theme.palette.text.primary,
                    },
                    "borderColor": theme.palette.border,
                    "fontWeight": "normal",
                    "borderRadius": 2.5,
                    "height": "40px",
                    "boxShadow": "none",
                    "px": 2,
                    ".MuiButton-startIcon": { ml: 0, mr: 2 },
                    "&:hover": {
                        boxShadow: "none",
                    },
                },
                chip: {
                    backgroundColor: theme.palette.primary.colors[50],
                    px: 2.5,
                    py: 1,
                },
                link: {
                    color: theme.palette.text.link,
                    cursor: "pointer",
                },
                codeInput: {
                    "& > .MuiInputBase-root": {
                        "height": theme.spacing(8),
                        "borderRadius": 0,
                        "& > input": {
                            height: "100%",
                            boxSizing: "border-box",
                            padding: 0,
                            fontSize: "32px",
                            fontWeight: 500,
                            color: theme.palette.primary.main,
                        },
                        "& fieldset": {
                            borderColor: `${theme.palette.border} ${lighten(theme.palette.border, 0.65)}`,
                            borderWidth: "1px",
                        },
                    },
                    "&:first-child > *": {
                        "paddingLeft": theme.spacing(0.75),
                        "& fieldset": {
                            marginLeft: "1px",
                            borderLeftColor: theme.palette.border,
                            borderBottomLeftRadius: theme.spacing(5),
                            borderTopLeftRadius: theme.spacing(5),
                        },
                    },
                    "&:last-child > *": {
                        "paddingRight": theme.spacing(0.75),
                        "& fieldset": {
                            marginRight: "1px",
                            borderRightColor: theme.palette.border,
                            borderBottomRightRadius: theme.spacing(5),
                            borderTopRightRadius: theme.spacing(5),
                        },
                    },
                },
                formHelperText: {
                    "marginInline": theme.spacing(1),
                    "& ul": {
                        "margin-block-start": 0,
                        "padding-inline-start": "1.66em",
                    },
                    "& > ul > li": {
                        "margin-block-end": theme.spacing(1.5),
                    },
                    "& li li": {
                        "list-style-type": '"- "',
                    },
                },
                menuItem: {
                    "height": "48px",
                    ".MuiTouchRipple-ripple": {
                        color: theme.palette.action.selected,
                    },
                    "&.Mui-selected,&.Mui-selected:hover,&.Mui-selected.Mui-focusVisible": {
                        backgroundColor: alpha(theme.palette.action.selected, theme.palette.action.selectedOpacity),
                    },
                    "&:hover,&.Mui-focusVisible": {
                        backgroundColor: alpha(theme.palette.action.hover, theme.palette.action.hoverOpacity),
                    },
                },
                textField: {
                    "height": theme.spacing(6),
                    "borderRadius": 3,
                    ".MuiOutlinedInput-notchedOutline": {
                        borderColor: theme.palette.border,
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: `1px solid ${theme.palette.action.active}`,
                    },
                    "& input": {
                        pl: 3,
                    },
                },
                tooltip: {
                    "& .MuiPaper-root ": {
                        backgroundColor: alpha(theme.palette.prowiseBrand.main, 0.95),
                        color: theme.palette.common.white,
                    },
                    "& .MuiTypography-root": {
                        fontSize: "0.75rem",
                    },

                },
                typography: {
                    "&.MuiTypography-h2": {
                        hyphens: "auto",
                        fontWeight: "300",
                        letterSpacing: "0px",
                        fontSize: "32px",
                        lineHeight: "40px",
                    },
                    "&.MuiTypography-h3": {
                        hyphens: "auto",
                        fontWeight: "300",
                        letterSpacing: "0px",
                        fontSize: "24px",
                        lineHeight: "30px",
                    },
                    "&.MuiTypography-h6": {
                        hyphens: "auto",
                        fontWeight: "400",
                        letterSpacing: "0px",
                        fontSize: "20px",
                        lineHeight: "30px",
                    },
                    "&.MuiTypography-body1": {
                        fontSize: "14px",
                        lineHeight: "21px",
                    },
                    "&.MuiTypography-body2": {
                        fontWeight: "300",
                        fontSize: "12px",
                        lineHeight: "16px",
                    },
                    "&.MuiTypography-caption2": {
                        fontSize: "12px",
                        fontWeight: "300",
                    },
                    "&.MuiTypography-inherit.code": {
                        "fontFamily": "monospace",
                        "padding": theme.spacing(1),
                        "mb": 2,
                        "color": theme.palette.prowiseBrand.main,
                        "backgroundColor": theme.palette.action.disabledBackground,
                        "borderRadius": theme.spacing(0.5),
                        "&.wide": {
                            letterSpacing: theme.spacing(0.5),
                            paddingRight: theme.spacing(0.5),
                        },
                    },
                },
                search: {
                    input: {
                        "height": theme.spacing(5),
                        ".MuiInputBase-root": {
                            "borderRadius": 4,
                            "paddingRight": 0.75,
                            ".MuiInputBase-input": {
                                pl: 3,
                                py: 1,
                            },
                        },
                    },
                    icon: {
                        padding: 0.5,
                        color: theme.palette.common.white,
                        backgroundColor: theme.palette.secondary.main,
                        borderRadius: "50%",
                    },
                },
            }),
    });

    return (
        <SharedUIThemeProvider theme={theme}>
            {props.children}
        </SharedUIThemeProvider>
    );
};
