import { type Palette, darken } from "@suid/material";
import { type ProwisePaletteColorOptions } from "../types";

/** Palette color names we want to override */
const prowiseBrandPalette = {
    50: "#E3F0F6",
    100: "#BBDAEA",
    200: "#94C3DD",
    300: "#6FACCF",
    400: "#569CC6",
    500: "#408CBF",
    600: "#3680B3",
    700: "#2B6FA2",
    800: "#225F90",
    900: "#154371", // Prowise blue
};

export const prowiseBlueColors = {
    50: "#E3F8F8",
    100: "#BBEDEC",
    200: "#93E1E2",
    300: "#73D3D7",
    400: "#63CACF",
    500: "#5EC0C8",
    600: "#58B0B6",
    700: "#519A9D",
    800: "#4B8687",
    900: "#40625F",
};

const prowiseRedPalette = {
    50: "#FFEAED",
    100: "#FFCBCF",
    200: "#FF9792",
    300: "#FE6B66",
    400: "#FF433B",
    500: "#FF3117",
    600: "#FD231B",
    700: "#EB1016",
    800: "#DD010E",
    900: "#CD0000",
};

export const prowiseBrandPaletteOptions: Required<ProwisePaletteColorOptions> = {
    colors: prowiseBrandPalette,
    light: prowiseBrandPalette[500],
    main: prowiseBrandPalette[900],
    dark: darken(prowiseBrandPalette[900], 0.2),
    contrastText: prowiseBrandPalette[700],
};

export const prowiseRedPaletteOptions: Required<ProwisePaletteColorOptions> = {
    colors: prowiseRedPalette,
    light: prowiseRedPalette[100],
    main: prowiseRedPalette[300],
    dark: prowiseRedPalette[500],
    contrastText: "#D32F2F",
};

export const prowiseYellowPaletteOptions: ProwisePaletteColorOptions = {
    light: "#FFD51A",
    main: "#FFD51A",
    dark: "#FFD51A",
    contrastText: "#D32F2F",
};

export const prowiseBluePaletteOptions: Required<ProwisePaletteColorOptions> = {
    colors: prowiseBlueColors,
    light: prowiseBlueColors[400],
    main: prowiseBlueColors[600],
    dark: prowiseBlueColors[800],
    contrastText: prowiseBlueColors[700],
};

export const prowiseDefaultPalette: Palette = {
    primary: prowiseBrandPaletteOptions,
    secondary: prowiseRedPaletteOptions,
    prowiseBrand: prowiseBrandPaletteOptions,
    prowiseBlue: prowiseBluePaletteOptions,
    prowiseRed: prowiseRedPaletteOptions,
    text: {
        primary: "rgba(0, 0, 0, 0.65)",
        secondary: "rgba(0, 0, 0, 0.5)",
        disabled: "rgba(0, 0, 0, 0.3)",
        link: "#1686c6",
    },
    common: {
        white: "#FFFFFF",
        black: "#000000",
        prowiseBrand: prowiseBrandPaletteOptions.main,
        prowiseBlue: prowiseBluePaletteOptions.main,
        prowiseRed: prowiseRedPaletteOptions.main,
        prowiseYellow: "#FFD51A",
    },
    background: {
        paper: "#FFFFFF",
        default: "#58B0B6",
    },
    error: prowiseRedPaletteOptions,
    warning: prowiseYellowPaletteOptions,
    info: prowiseBluePaletteOptions,
    success: prowiseBluePaletteOptions,
    divider: "rgba(0, 0, 0, 0.12)",
    border: "rgba(0, 0, 0, 0.12)",
    contrastThreshold: 2,
    mode: "light",
    tonalOffset: 0,
    action: {
        hoverOpacity: 0.12,
        focusOpacity: 0.16,
        selectedOpacity: 0.2,
        activatedOpacity: 0.2,
        disabledOpacity: 0.12,
        active: prowiseBrandPaletteOptions.main, // IconButtons
        hover: prowiseBluePaletteOptions.main,
        selected: prowiseBluePaletteOptions.main,
        disabled: "rgba(0, 0, 0, 0.3)", // Disabled text
        disabledBackground: "rgba(0, 0, 0, 0.12)",
        focus: "#FFFFFF",
    },
};
