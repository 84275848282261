import { type Config, type PhetchResponse, Phetch } from "@prowise/phetch";
import i18next from "i18next";
import { type Accessor, createResource, createSignal, type Resource } from "solid-js";
import { type SetStoreFunction } from "solid-js/store";
import { useDevelopmentParams } from "./useDevelopmentParams";
import { useInteractionId } from "./useInteractionId";
import { config } from "../config/index.js";
import { type StageError, type Stage, type State, ExceptionIdentifier } from "../domain";
import { type BCP47, getLanguageLocale } from "../utilities/defaultLocales.js";

const STAGE_URI = (interactionId: string): string => `api/frontend/stage/${interactionId}`;
const RESET_URI = (interactionId: string): string => `api/frontend/reset/${interactionId}`;

/** Use Stage data interface to tie interactivity to. */
interface UseStageData {
    /** Stage data (from the API) resource. */
    stageData: Resource<Stage | StageError>;
    /** Refetch method for stage data using requestData. */
    refetch: () => void;
    /** Request data to fill into the forms and sent off to the API. */
    requestData: Accessor<State | undefined>;
    /** Request data setter. */
    setRequestData: SetStoreFunction<State>;
    /** Reset state: provide an initial stage. */
    resetState: () => Promise<Stage | StageError>;
}

// NOTE: Request data initially is empty so its resource will not yet execute.
const [requestData, setRequestData] = createSignal<State | undefined>();

const validateRequestData = (postData: State, requestConfig: Config): boolean => {
    if (!Object.keys(postData).length) return false;

    requestConfig.headers = { ...requestConfig.headers, "Content-Type": "application/json" };
    requestConfig.body = JSON.stringify(postData);
    return true;
};

/**
 * Is type of error.
 *
 * @param e The object to test.
 * @returns Whether the object is an Error object.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isError<T extends Error>(e: any): e is T {
    return e instanceof Error;
}

/**
 * Response error interceptor.
 *
 * @param response Phetch Response.
 * @returns Phetch Response, optionally modified.
 */
async function responseErrorInterceptor<T extends PhetchResponse<Stage | StageError>>(response: T): Promise<T> {
    if (!response.data) {
        // Set HTTP statuscode error
        const errorCode = `HTTP_${response.status}`;
        response.data = { id: errorCode, message: response.statusText, reference: errorCode } as StageError;
    } else if ("error" in response.data && isError(response.data.error)) {
        // Determine the type of error.
        // See https://developer.mozilla.org/en-US/docs/Web/API/fetch#exceptions
        let exceptionIdentifier: ExceptionIdentifier;
        switch (response.data.error.name) {
            case "TypeError":
                // TypeError is most likely a network error or malformed request
                exceptionIdentifier = ExceptionIdentifier.NETWORK_ERROR;
                break;
            case "DOMException":
            case "AbortError":
                // Fetch abort
                exceptionIdentifier = ExceptionIdentifier.ABORT_ERROR;
                break;
            case "SyntaxError":
                // JSON parsing failed
                exceptionIdentifier = ExceptionIdentifier.JSON_ERROR;
                break;
            default:
                exceptionIdentifier = ExceptionIdentifier.UNKNOWN;
                break;
        }

        // Assign the error message.
        response.data = { id: exceptionIdentifier, message: String(response.data.error) } as StageError;
    }

    return response;
}

useDevelopmentParams();
Phetch.interceptors.responseError.add(responseErrorInterceptor);

// Add X-local to the headers of requests send to the SSO backend.
Phetch.interceptors.request.add(async (request: Config): Promise<Config> => {
    if (!request.baseUrl) {
        return request;
    }
    const url = new URL(request.baseUrl);
    if (url.origin !== config.baseApi.replace(/\/$/, "")) {
        return request;
    }

    request.headers ??= new Headers();
    if (Array.isArray(request.headers)) {
        request.headers.push(["X-Locale", getLanguageLocale(i18next.language as BCP47).locale]);
    } else if (request.headers instanceof Headers) {
        request.headers.set("X-Locale", getLanguageLocale(i18next.language as BCP47).locale);
    } else {
        request.headers = {
            ...request.headers,
            "X-Locale": getLanguageLocale(i18next.language as BCP47).locale,
        };
    }
    return request;
});

const fetchStageData = async (
    requestState: State,
    { refetching }: { refetching: boolean },
): Promise<Stage & { reference?: string } | StageError> => {
    const { interactionId, setInteractionIdFromUrl } = useInteractionId();

    // Try to fetch it from URL
    setInteractionIdFromUrl(window.location.href);

    // If not set, you likely have no ID
    if (!interactionId()) {
        return {
            id: ExceptionIdentifier.NO_INTERACTION_ID,
            message: "No interaction started yet, please open a compatible app to start login (through OIDC).",
        } as StageError;
    }

    // Check if object was injected into window
    if (window.data) {
        const { data } = window;

        // Erase the data and prevent any overrides
        Object.defineProperty(window, "data", {
            value: undefined,
            writable: false,
            configurable: false,
            enumerable: false,
        });
        return data;
    }

    const requestConfig: unknown = {
        headers: {
            "X-Referer": window.location.origin + window.location.pathname + window.location.search,
        },
    };
    if (!refetching && validateRequestData(requestState, requestConfig as Config)) {
        const response = await Phetch.post<Stage>(STAGE_URI(interactionId()), requestConfig as Config);
        // @ts-expect-error - we use optional chaining operator and only change in a specific case.
        if (response?.data?.id === "NO_FLOW") {
            // @ts-expect-error - we just checked it.
            response.data.id = ExceptionIdentifier.INTERACTION_UNKNOWN;
        }
        return {
            ...response.data,
            reference: response.headers?.get("X-Span-Id") ?? undefined,
        };
    }

    const getResponse = await Phetch.get<Stage>(STAGE_URI(interactionId()), requestConfig as Config);
    // @ts-expect-error - we use optional chaining operator and only change in a specific case.
    if (getResponse?.data?.id === "NO_FLOW") {
        // @ts-expect-error - we just checked it.
        getResponse.data.id = ExceptionIdentifier.INTERACTION_UNKNOWN;
    }
    return {
        ...getResponse.data,
        reference: getResponse.headers?.get("X-Span-Id") ?? undefined,
    };
};

const [stageData, { refetch }] = createResource<Stage | StageError, State, never>(requestData, fetchStageData);

const resetState = async (): Promise<Stage | StageError> => {
    const { interactionId } = useInteractionId();

    // If not set, you likely have no ID
    if (!interactionId()) {
        return {
            id: ExceptionIdentifier.NO_INTERACTION_ID,
            message: "No interaction started yet, please open a compatible app to start login (through OIDC).",
        } as StageError;
    }

    // Reset the state and refetch the stage.
    await Phetch.post(RESET_URI(interactionId()));
    setRequestData({} as State);

    const result = stageData();
    if (!result) {
        return {
            id: ExceptionIdentifier.INTERACTION_UNKNOWN,
            message: "Lost stage flow.",
        } as StageError;
    }

    return result;
};

export const useStageData = (): UseStageData => ({ stageData, refetch, requestData, setRequestData, resetState });
