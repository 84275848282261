import { type Accessor, createSignal } from "solid-js";
import { config } from "../config";

const [interactionId, setInteractionId] = createSignal("");

export const useInteractionId = (): {
    interactionId: Accessor<string>;
    setInteractionIdFromUrl: (url: string) => void;
    clearInteractionId: () => void;
} => {
    const storedId = sessionStorage.getItem("interactionId");
    if (storedId) {
        setInteractionId(storedId);
    }

    const setInteractionIdFromUrl = (url: string): void => {
        if (!url) return;

        try {
            const location = new URL(url);
            const interactionIdParam = location.searchParams.get("interactionId");
            if (interactionIdParam) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- We just checked its presence
                setInteractionId(interactionIdParam);
                sessionStorage.setItem("interactionId", interactionIdParam);

                // Remove query from the history such that it looks much prettier.
                location.searchParams.delete("interactionId");
                if (config.debug.URLHistory) {
                    window.history.pushState(null, document.title ?? config.version.productName, location);
                } else {
                    window.history.replaceState(null, document.title ?? config.version.productName, location);
                }
            }
        } catch (e) {
            // Pass
        }
    };

    const clearInteractionId = (): void => {
        setInteractionId("");
        sessionStorage.removeItem("interactionId");
    };

    return { interactionId, setInteractionIdFromUrl, clearInteractionId };
};
