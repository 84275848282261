import { Stack, TextField, useTheme } from "@suid/material";
import createRef from "@suid/system/createRef";
import { type ChangeEvent } from "@suid/types";
import { createEffect, createSignal, For, splitProps, type Component, batch } from "solid-js";
import { createStore } from "solid-js/store";
import { type CodeInputProps } from "./index";

const [pattern, setPattern] = createSignal<RegExp>();

/** Code item. */
type CodeItem = {
    /** Code character. */
    char: string;
    /** Code input element. */
    element: {
        /** HTML input ref. */
        ref: HTMLInputElement;
    };
};

/**
 * Sanitize input string.
 *
 * @param input The string to sanitize.
 * @param toUpper Whether to convert to upper case.
 * @returns The sanitized string, according to the stored regex pattern.
 */
const sanitizeInput = (input: string, toUpper?: boolean): string => {
    const sanitizedInput = input.replace(pattern() ?? "", "");
    return toUpper ? sanitizedInput.toUpperCase() : sanitizedInput;
};

/**
 * Check if event is keyboard event
 *
 * @param event The event to check
 * @returns Type guard KeyboardEvent
 */
function isKeyboardEvent(event: unknown): event is KeyboardEvent {
    switch (event?.type) {
        case "keydown":
        case "keypress":
        case "keyup":
            return true;
        default:
            return false;
    }
}

export const CodeInput: Component<CodeInputProps> = (props) => {
    const theme = useTheme();
    const [local, others] = splitProps(props, [
        "value",
        "length",
        "fullWidth",
        "autoFocus",
        "gutterBottom",
        "onInput",
        "onCode",
        "pattern",
        "forceUppercase",
    ]);
    const [inputValues, setInputValues] = createStore<CodeItem[]>([]);
    const [numeric, setNumeric] = createSignal(false);

    createEffect(() => {
        // Create regex that removes everything not part of local.pattern.
        const localPattern = local.pattern ?? "[0-9]";
        setPattern(new RegExp(`(?:(?!${localPattern}).)`, "g"));

        // Recognize if the input pattern is numeric, i.e. [0-9] or [07-9].
        setNumeric(!!(/^\[\d+(?:-\d+)?\]$/.exec(localPattern)));

        // Create an array of strings.
        batch(() => {
            const length = local.length ?? 6;

            // Remove items if length has shrunk
            if (length < inputValues.length) {
                setInputValues(inputValues.filter((_, idx) => idx < length));
            }

            for (let n = 0; n < length; ++n) {
                if (n >= inputValues.length) {
                    // Create new entry if it did not yet exist
                    setInputValues(n, { char: local.value ? local.value[n] : "", element: createRef<HTMLInputElement>() });
                } else {
                    // Don't modify the ref; only update char
                    setInputValues(n, "char", local.value ? local.value[n] : "");
                }
            }
        });
    });

    createEffect(() => {
        // Skip logic if some fields are still empty
        if (inputValues.some((inputValue) => !inputValue.char)) return;

        local.onCode?.(inputValues.map((inputValue) => inputValue.char).join(""));
    });

    const inputPasted = (event: ClipboardEvent, index: number): void => {
        const data = sanitizeInput(event.clipboardData?.getData("text") ?? "", local.forceUppercase);

        const length = Math.min(data.length, (local.length ?? 6) - index);

        for (let n = 0; n < length; ++n) {
            setInputValues(n + index, "char", data[n]);
        }

        setTimeout(() => inputValues[Math.min(index + length, inputValues.length - 1)].element.ref.focus());
    };

    const inputChanged = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | KeyboardEvent,
        index: number,
    ): void => {
        if (isKeyboardEvent(event)) {
            if (event.key === "Backspace" || event.key === "Delete") {
                for (let n = index; n < inputValues.length; ++n) {
                    setInputValues(n, "char", "");
                }
                if (index) inputValues[index - 1].element.ref.focus();
            }
            return;
        }

        const sanitizedValue = sanitizeInput(event.currentTarget.value, local.forceUppercase);

        setInputValues(index, "char", sanitizedValue);

        // Don't continue of we don't have input.
        if (!sanitizedValue) {
            // Pass an empty code if this input was explicitly cleared.
            if (!event.currentTarget.value) local.onCode?.("");

            return;
        }

        inputValues[index].element.ref.value = sanitizedValue;

        // If we have a next input, focus on it
        if (index + 1 < inputValues.length) {
            inputValues[index + 1].element.ref.focus();
        } else {
            const emptyInput = inputValues.find((elem) => !elem.element.ref.value);
            if (emptyInput) emptyInput.element.ref.focus();
        }
    };

    return (
        <Stack
            justifyContent="center"
            alignItems="center"
            spacing={0}
            direction="row"
            sx={{ mb: local.gutterBottom ? 2 : 0 }}
            data-testid="CodeInput"
        >
            <For each={inputValues}>
                {(value, index) => (
                    <TextField
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        inputRef={value.element as any}
                        sx={{ minWidth: `calc(${theme.spacing(3)} + 1em)`, ...theme.mixins.codeInput }}
                        autoFocus={local.autoFocus ? !index() : false}
                        autoComplete="off"
                        value={value.char}
                        onChange={(e) => { inputChanged(e, index()); }}
                        onFocus={(event) => {
                            const emptyInputIndex = inputValues.findIndex((elem) => !elem.element.ref.value);
                            if (emptyInputIndex >= 0 && emptyInputIndex < index()) {
                                inputValues[emptyInputIndex].element.ref.focus();
                                // Clear the focus rectangle since it behaves buggy
                                const parent = event.currentTarget.parentElement;
                                if (parent) setTimeout(() => { parent.classList.remove("Mui-focused"); }, 0);
                            } else {
                                event.currentTarget.setSelectionRange(0, -1);
                            }
                        }}
                        InputProps={{ sx: { ...theme.mixins.textField, borderRadius: 2 } }}
                        inputProps={{
                            autocomplete: "one-time-code",
                            inputmode: numeric() ? "numeric" : "text",
                            maxLength: 1,
                            style: { "text-align": "center" },
                            onpaste: (event: ClipboardEvent) => inputPasted(event, index()),
                            onkeyup: (event: KeyboardEvent) => { inputChanged(event, index()); },
                        }}
                        {...others}
                    />
                )}
            </For>
        </Stack>
    );
};
