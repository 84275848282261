import { Paper, useTheme } from "@suid/material";
import { Show, type ParentComponent } from "solid-js";

import { AppFrameStyled } from "./AppFrame.styled";
import { type AppFrameProps } from "./index";

export const AppFrame: ParentComponent<AppFrameProps> = (props) => {
    const theme = useTheme();

    return (<AppFrameStyled {...props}>
        <div class="frame"
            classList={{ fullscreen: props.fullscreen, minheight: props.minContentHeight }}>
            <Paper sx={{
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                ...theme.mixins.paper,
                p: props.fullscreen ? 1.5 : 6,
                pb: !props.minContentHeight && props.fullscreen ? 0 : undefined,
            }}>
                {props.children}
            </Paper>
            <Show when={!props.fullscreen}>
                {props.footer}
            </Show>
        </div>
    </AppFrameStyled>);
};
