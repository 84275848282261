import i18next, { t } from "i18next";
import { createMemo } from "solid-js";

import { authSourceNames } from "../domain/authSourceNames";
import { type AuthSourceOptions } from "../domain/AuthSourceOptions.type";

/** Locale item. */
type LocaleItem = {
    /** The readable name of the locale. */
    name: string;
    /** Locale */
    locale: BCP47;
    /** Optional collation group */
    group?: string;
};

/** Locale type, ordered alphabetically by language, preference region fallback. */
export enum BCP47 {
    BG_BG = "bg-BG",
    CS_CZ = "cs-CZ",
    CY_GB = "cy-GB",
    DA_DK = "da-DK",
    DE_DE = "de-DE",
    EN_GB = "en-GB",
    EN_US = "en-US",
    EN_AU = "en-AU",
    ES_ES = "es-ES",
    FR_FR = "fr-FR",
    FR_BE = "fr-BE",
    HU_HU = "hu-HU",
    HR_HR = "hr-HR",
    IT_IT = "it-IT",
    NB_NO = "nb-NO",
    NL_NL = "nl-NL",
    NL_BE = "nl-BE",
    PL_PL = "pl-PL",
    PT_PT = "pt-PT",
    RO_RO = "ro-RO",
    SV_SE = "sv-SE",
}

// ISO_3166-1 alpha 2 or ISO 3166-2 country/region codes
export enum Region {
    AU = "AU",
    BE = "BE",
    BE_VLG = "BE-VLG",
    BE_WAL = "BE-WAL",
    DE = "DE",
    DK = "DK",
    ES = "ES",
    FR = "FR",
    GB = "GB",
    GB_WLS = "GB-WLS",
    IT = "IT",
    NO = "NO",
    NL = "NL",
    PT = "PT",
    SK = "SE",
    US = "US",
}

// cspell:disable -- Disable spell checking on localised country names.
const defaultIndex = 5;
const defaultLanguageList: LocaleItem[] = [
    // Match these to locize
    // Note: these are ordered on BCP47 language and preferred country for use in the menu.
    { locale: BCP47.DE_DE, name: "Deutsch", group: "primary" },
    { locale: BCP47.NL_NL, name: "Nederlands", group: "primary" },
    { locale: BCP47.NL_BE, name: "Vlaams", group: "primary" },
    { locale: BCP47.FR_BE, name: "Français (Belgique)", group: "primary" },
    { locale: BCP47.FR_FR, name: "Français (France)", group: "primary" },

    { locale: BCP47.EN_GB, name: "English (United Kingdom)", group: "english" },
    { locale: BCP47.EN_US, name: "English (United States)", group: "english" },
    { locale: BCP47.EN_AU, name: "English (Australia)", group: "english" },

    { locale: BCP47.CS_CZ, name: "Čeština" },
    { locale: BCP47.DA_DK, name: "Dansk" },
    { locale: BCP47.ES_ES, name: "Español" },
    { locale: BCP47.HR_HR, name: "Hrvatski" },
    { locale: BCP47.IT_IT, name: "Italiano" },
    { locale: BCP47.HU_HU, name: "Magyar" },
    { locale: BCP47.NB_NO, name: "Norsk bokmål" },
    { locale: BCP47.PL_PL, name: "Polski" },
    { locale: BCP47.PT_PT, name: "Português" },
    { locale: BCP47.RO_RO, name: "Română" },
    { locale: BCP47.SV_SE, name: "Svenska" },
    { locale: BCP47.CY_GB, name: "Welsh" },
    { locale: BCP47.BG_BG, name: "български" },
];

const fallbackRegionNames: Record<Region, string> = {
    [Region.AU]: "Australia",
    [Region.GB]: "United Kingdom",
    [Region.GB_WLS]: "Wales",
    [Region.US]: "United States",
    [Region.DE]: "Germany",
    [Region.DK]: "Denmark",
    [Region.NO]: "Norway",
    [Region.SK]: "Sweden",
    [Region.ES]: "Spain",
    [Region.IT]: "Italy",
    [Region.FR]: "France",
    [Region.BE]: "Belgium",
    [Region.PT]: "Portugal",
    [Region.BE_VLG]: "Vlaanderen",
    [Region.BE_WAL]: "Wallonie",
    [Region.NL]: "Netherlands",
};
// cspell:enable -- Restore spell checking.

export const supportedLanguages = defaultLanguageList.map((language) => language.locale);

/**
 * Get Language Locale
 *
 * @param locale The region identifier we want to get the locale from
 * @returns Locale item, either an exact match, language match or global fallback.
 */
export function getLanguageLocale(locale: BCP47): LocaleItem {
    // Find given locale on exact match
    let result = defaultLanguageList.find((language) => language.locale === locale);
    if (result) return result;

    // Match on given language only; let it fall back to the base language default (i.e.: fr -> fr-FR)
    const localeLangPart = locale?.slice(0, 2);
    const langLocale = Object.values(BCP47).find(
        (supportedLanguage) => supportedLanguage.startsWith(localeLangPart));
    result = langLocale && defaultLanguageList.find((language) => language.locale === langLocale);

    // Fall back to en-GB
    return result ?? defaultLanguageList[defaultIndex];
}

/**
 * Type guard for BCP47 or Region.
 *
 * @param id A BCP47 or Region identifier.
 * @returns Whether id is a BCP47 type guard.
 */
export function isLanguage(id: BCP47 | Region): id is BCP47 {
    return defaultLanguageList.some((language) => language.locale === id);
}

/**
 * Get translation of sign in buttons
 *
 * @param provider login method
 * @param name login "provider" name
 *
 * @returns translation of login method
 */
export const getLoginWithProviderLabel = (provider: AuthSourceOptions, name?: string): string => {
    // Explicit brand and product names are used integrally.
    const providerName = name ?? authSourceNames[provider];
    if (providerName) return t("auth_select.btn.sign_in_with_{target}", { target: providerName });

    // NOTE: translations are dependant on the current i18next.language
    const loginWithProviderLabels = createMemo<Partial<Record<AuthSourceOptions, string>>>(() => ({
        "email": t("auth_select.btn.sign_in_with_email"),
        "second-device-login": t("auth_select.btn.sign_in_with_second_device"),
        "picture": t("auth_select.btn.sign_in_with_picture"),
        "external-auth-school": t("auth_select.btn.sign_in_with_external_auth_school"),
        "account-unknown": t("auth_select.btn.sign_in_with_account_unknown"), // Should not occur

        "leer-id": t("auth_select.btn.sign_in_with_leer_id"),
        "vlaamse-overheid": t("auth_select.btn.sign_in_with_vlaamse_overheid"),
    }));

    // Return translated provider label with a fallback onto id.
    // eslint-disable-next-line solid/reactivity
    return loginWithProviderLabels()[provider] ?? t("auth_select.btn.sign_in_with_{target}", { target: provider });
};

/**
 * Get translation of register using/with buttons
 *
 * @param provider register method
 * @param name register "provider" name
 *
 * @returns translation of register method
 */
export const getRegisterWithProviderLabel = (provider: AuthSourceOptions, name?: string): string => {
    // Explicit brand and product names are used integrally.
    const providerName = name ?? authSourceNames[provider];
    if (providerName) return t("auth_select.btn.register_using_{target}", { target: providerName });

    // NOTE: translations are dependant on the current i18next.language
    const registerWithProviderLabels = createMemo<Partial<Record<AuthSourceOptions, string>>>(() => ({
        "external-auth-school": t("auth_select.btn.register_with_external_auth_school"),
    }));

    // Return translated provider label with a fallback onto id.
    // eslint-disable-next-line solid/reactivity
    return registerWithProviderLabels()[provider] ?? t("auth_select.btn.register_using_{target}", { target: provider });
};
/**
 * Generate region label in the current selected language.
 *
 * @param region The region locale we want the label for.
 * @returns A string containing a region name, if available.
 */
export const generateRegionLabel = (region: Region): string => {
    if (!region) return "";

    let label = fallbackRegionNames[region] ?? region;

    try {
        // Only translate the country part of the locale
        label = new Intl.DisplayNames([i18next.language], { type: "region" }).of(region) ?? label;
        label = label.charAt(0).toUpperCase() + label.slice(1);
    } catch {
        // Pass
        console.warn(`Auto translation for region ${region} failed.`);
    }

    // Make sure the (auto) generated strings are not the same as the input locale.
    if (label.startsWith(region)) {
        label = fallbackRegionNames[region] ?? region;
    }

    return label;
};
