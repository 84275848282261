import { ExpandMore } from "@suid/icons-material";
import { Box, Button, Divider, Menu, useTheme } from "@suid/material";
import { type PopoverActions } from "@suid/material/Popover";
import { type SxProps } from "@suid/system";
import { For, type Component, createEffect, createSignal, type JSX, onMount } from "solid-js";
import { type LocaleMenuProps } from ".";
import { type BCP47, type Region, generateRegionLabel, getLanguageLocale, isLanguage, supportedLanguages } from "../../utilities/defaultLocales";
import { Flag } from "../Flag";
import { TreeItem, type TreeItemProps } from "../TreeItem";

/**
 * Create language menu item.
 *
 * @param locale The language identifier.
 * @param similarItems Whether the language base occurs more often.
 * @returns The menu item.
 */
function createLanguageItem(locale: BCP47): TreeItemParent<BCP47> {
    const localeItem = getLanguageLocale(locale);
    const item: TreeItemParent<BCP47> = {
        id: locale,
        label: localeItem.name,
        showIcon: true,
        group: localeItem.group,
    };

    return item;
}

/**
 * Create region menu item.
 *
 * @param region The region identifier.
 * @returns The menu item.
 */
function createRegionItem(region: Region): TreeItemParent<Region> {
    const base = region.substring(0, 2);
    const isBase = region === base;
    const item: TreeItemParent<Region> = {
        id: region,
        label: generateRegionLabel(region),
        level: isBase ? 0 : 1,
    };

    return item;
}

/**
 * Create a menu "tree" Item.
 *
 * @param locale The locale (id) of the list item.
 * @param similarItems Whether this locale has other locales with the same base.
 * @returns Locale details item to draw as tree item.
 */
function createTreeItem<T extends BCP47 | Region>(locale: T): TreeItemParent<T> {
    const treeItem = isLanguage(locale)
        ? createLanguageItem(locale)
        : createRegionItem(locale);
    return treeItem as TreeItemParent<T>;
}

/**
 * Build tree.
 *
 * @param treeList The (flat) list of tree items.
 * @param selectedLocaleId The selected locale id so we can expand its ancestors.
 * @returns An actual tree of the tree items.
 */
function buildTree<T extends BCP47 | Region>(
    treeList: Map<T, TreeItemProps<T>>,
    selectedLocaleId: T): Array<TreeItemParent<T>> {
    treeList.forEach((treeItem, locale) => {
        // Skip root node
        if (!locale) return;

        if (treeItem.id === selectedLocaleId) {
            treeItem.selected = true;
        }

        // Slice off the last 'dashed segment' to determine its parent.
        const parentLocale = locale.slice(0, Math.max(0, locale.lastIndexOf("-"))) as T;

        if (!treeList.has(parentLocale)) {
            console.warn(`Parent not found for: ${locale}`);
            treeList.set(parentLocale, createTreeItem(parentLocale));
        }
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- We just created it if it didn't exist.
        const parent = treeList.get(parentLocale)! as TreeItemParent<T>;
        if (!parent?.childItems) {
            parent.childItems = [];

            // Expand if the descendant is selected
            parent.expanded = selectedLocaleId?.startsWith(parent.id) ?? false;
        }

        // Set as actual child and remove from the list
        parent.childItems.push(treeItem as TreeItemParent<T>);
    });

    return [];
}

/**
 * Make tree.
 *
 * @param localeIds The Locale id (flat) list to create tree items from.
 * @param selectedLocale The locale id that is selected.
 * @returns A sorted tree list of tree items.
 */
function localeToList<T extends BCP47 | Region>(localeIds: T[], selectedLocale: T): Array<TreeItemParent<T>> {
    const listItems: Array<TreeItemParent<T>> = [];
    let prevItem: TreeItemParent<T> | undefined;

    localeIds.forEach((localeId) => {
        const item = createTreeItem(localeId);
        if (localeId === selectedLocale) item.selected = true;

        if (prevItem && prevItem.group !== item.group) {
            // Add a separator
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Separator is a special case.
            const separator: TreeItemParent<any> = {
                id: undefined,
                label: "",
            };

            listItems.push(separator);
        }
        prevItem = item;

        listItems.push(item);
    });

    return listItems;
}

/**
 * Make tree.
 *
 * @param localeIds The Locale id (flat) list to create tree items from.
 * @param selectedLocale The locale id that is selected.
 * @returns A sorted tree list of tree items.
 */
function localeToTree<T extends BCP47 | Region>(localeIds: T[], selectedLocale: T): Array<TreeItemParent<T>> {
    // Make a (flat) Map
    const listItems = new Map(localeIds.map((localeId) =>
        [localeId, createTreeItem(localeId)]));

    // Create dummy root item that contains the initial list
    listItems.set("" as T, createTreeItem("" as T));

    // Turn it into a tree list
    buildTree(listItems, selectedLocale);
    const treeItems = listItems.get("" as T)?.childItems ?? [];

    return treeItems;
}

/**
 * Draw tree.
 *
 * @param treeItems A tree representation of the tree items.
 * @param onClick Method that will be invoked when clicked on the item.
 * @param action Optional popover action ref to reposition the menu when the tree has changed
 * @returns Elements in a tree structure.
 */
function drawTree<T extends BCP47 | Region>(
    treeItems: Array<TreeItemParent<T>>,
    onClick: (treeItem: TreeItemProps<T>) => void,
    action?: PopoverActions,
): JSX.Element {
    return (
        <For each={treeItems}>
            {(treeItem) => {
                // Item without id or label is a separator
                if (!treeItem.id && !treeItem.label) return <Divider/>;

                return (<TreeItem {...treeItem} onClick={onClick} action={action}>
                    {treeItem.childItems ? drawTree(treeItem.childItems, onClick, action) : null}
                </TreeItem>);
            }}
        </For>
    );
}

/**
 * Find Locale Tree item.
 *
 * @param locales The (tree) list of locales.
 * @param localeId The locale id we want to find.
 * @returns The locale tree item, if found.
 */
function findLocale<T extends BCP47 | Region>(
    locales: Array<TreeItemParent<T>>,
    localeId: T): TreeItemParent<T> | undefined {
    let found: TreeItemParent<T> | undefined;

    locales.some((locale) => {
        if (locale.id === localeId) {
            found = locale;
            return true;
        }

        // Recursion
        if (locale.childItems) {
            found = findLocale(locale.childItems, localeId);
        }
        return !!found;
    });

    return found;
}
/** */
type TreeItemParent<T extends BCP47 | Region = BCP47 | Region> = TreeItemProps<T> & {
    /** */
    childItems?: Array<TreeItemParent<T>>;
};

export const LocaleMenu: Component<LocaleMenuProps> = (props) => {
    const theme = useTheme();

    const [locales, setLocales] = createSignal<TreeItemParent[]>([]);
    const [selectedLocale, setSelectedLocale] = createSignal(
        // eslint-disable-next-line solid/reactivity -- default is only used initially, ignore reactive state
        getLanguageLocale((props.selectedLocale ?? props.default ?? locales()[0]?.id) as BCP47)
            .locale as BCP47 | Region,
    );
    const [label, setLabel] = createSignal(
        // eslint-disable-next-line solid/reactivity
        getLanguageLocale((props.selectedLocale ?? props.default ?? locales()[0]?.id) as BCP47).name,
    );
    const [flag, setFlag] = createSignal<BCP47 | undefined>(
        // eslint-disable-next-line solid/reactivity
        isLanguage(selectedLocale()) ? selectedLocale() as BCP47 : undefined,
    );

    const [anchorEl, setAnchorEl] = createSignal<HTMLElement>();
    const open = (): boolean => Boolean(anchorEl());
    const handleClose = (newLocale?: TreeItemProps<Region | BCP47>): void => {
        if (!newLocale) {
            setAnchorEl(undefined);
            return;
        }

        // Find locale for given id.
        const localeId = props.handleChange?.(newLocale.id) ?? newLocale.id;
        const locale = findLocale(locales(), localeId);
        if (locale) {
            setSelectedLocale(localeId);
            setLabel(locale.label);
            if (isLanguage(localeId)) {
                setFlag(localeId);
            }
        }
        setAnchorEl(undefined);
    };

    createEffect(() => {
        const items = props.items ?? supportedLanguages;
        setLocales(props.showAsTree ? localeToTree(items, selectedLocale()) : localeToList(items, selectedLocale()));
    });

    onMount(() => {
        const selected = props.selectedLocale ?? props.default ?? locales()[0]?.id;
        if (props.items) {
            setLocales(props.showAsTree ? localeToTree(props.items, selected) : localeToList(props.items, selected));
        }

        const locale = findLocale(locales(), selected);
        if (locale) {
            setLabel(locale.label);
        }
    });

    /*
    Note: the popup can be aligned: as followed;
     - align the flags
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: 12 }}
     - right align the menu (matches chevron):
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
    */
    const [action, setAction] = createSignal<PopoverActions>();

    return (
        <Box data-testid="LocaleMenu" sx={props.sx}>
            <Button
                sx={{
                    ...theme.mixins.buttonOutlined,
                    ...props.sxButton,
                } as SxProps}
                disabled={props.disabled}
                onClick={(event: Event) => {
                    setAnchorEl(event.currentTarget as HTMLElement);
                }}
                variant={props.variant ?? "text"}
                color={props.color}
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- We've just checked it
                startIcon={flag() && props.showFlag && props.showLabel ? <Flag locale={flag()!} /> : null}
                endIcon={props.showLabel ? <ExpandMore /> : null}
            >
                {props.showLabel ? <span style={{ "overflow": "hidden", "text-overflow": "ellipsis", "width": "100%", "text-align": "left" }}>{label()}</span> : null}
                {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- We've just checked it */}
                {flag() && props.showFlag && !props.showLabel ? <Flag locale={flag()!} /> : null}
            </Button>
            <Menu
                action={setAction}
                PaperProps={{ sx: { borderRadius: 2 } }}
                id="basic-menu"
                anchorEl={anchorEl()}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={open()}
                onClose={() => handleClose()}
                MenuListProps={{ "aria-labelledby": "basic-button" }}
            >
                {drawTree(locales(), handleClose, action())}
            </Menu>
        </Box>
    );
};
