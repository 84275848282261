import { CircularProgress, Stack, Typography } from "@suid/material";
import { t } from "i18next";
import { createMemo, createSignal, onMount, type ParentComponent, Show } from "solid-js";
import { type LoaderProps } from ".";
import { isChromebook } from "../../utilities/UserAgentHelper";
import { LoginDialog } from "../LoginDialog";

export const Loader: ParentComponent<LoaderProps> = (props) => {
    // Set show content to false if we have a delay.
    // eslint-disable-next-line solid/reactivity
    const [showContent, setShowContent] = createSignal(!props.delay);

    onMount(() => {
        if (props.delay) {
            setTimeout(() => {
                setShowContent(true);
            }, props.delay === true ? 1500 : props.delay);
        }
    });

    // The actual content
    const content = createMemo(() => <Show when={showContent()}>
        <Stack direction="column" alignItems="center" gap={2} sx={{ overflow: "hidden" }}>
            <CircularProgress />
            <Show when={props.children} fallback={
                !props.noFallbackTitle && <Typography>{t("general.title.loading")}</Typography>
            }>
                {props.children}
            </Show>
        </Stack>
    </Show>);

    // Wrap content in a dialog, if requested
    return <>{props.dialog ? <LoginDialog
        id="loading"
        fullscreen={isChromebook()}
        minContentHeight={isChromebook()}
        orientation={isChromebook() ? "horizontal" : undefined}
        fullHeight={isChromebook()}
        centerContent
        errors={[]}
    >{content()}</LoginDialog> : content()}</>;
};
