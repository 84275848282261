import { type Component, createEffect } from "solid-js";
import { useInteractionId } from "src/hooks";
import { config } from "../config";

/**
 * Redirect "component".
 *
 * This component will not output any html, but instead
 * will redirect to the provided URL
 * or reload the page if no URL is provided.
 *
 * @param props The properties object.
 * @param props.url The URL to redirect to.
 * @param props.finalize Should we finalize and clear the interaction ID
 * @returns False.
 */
export const Redirect: Component<{ url: string; finalize?: boolean }> = (props) => {
    createEffect(() => {
        const { clearInteractionId } = useInteractionId();

        if (props.finalize ?? false) {
            clearInteractionId();
        }

        if (!props.url) {
            window.location.reload();
        } else if (config.debug.URLHistory) {
            window.location.href = props.url;
        } else {
            // Prevent browser history clutter
            window.location.replace(props.url);
        }
    });
    return <></>;
};

export default Redirect;
